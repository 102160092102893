import { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Help } from "../Components/Help/Help";
import "./ProtectedRoutes.css";

export const ProtectedRoutes = () => {
    // const [verified] = useState<boolean>(
    //     (Boolean(localStorage.getItem('email_verified'))) && (new Date(localStorage.getItem('exp') || "")) > new Date()
    // )

    const [verified] = useState(true)

    // useEffect(() => {

    //     if (!verified) {
    //         googleLogout()
    //     }

    // }, [verified])

    return (
        verified ? (
            <div className="wrapper">
                <Help />
                <Outlet />
            </div>
        ) : (<Navigate to={"/login"} />)
    )
}